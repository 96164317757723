.containerSuccessScreen {
    background-color: rgba(255, 234, 219, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    height: 100%;
    

}
.containersvg {
  
    margin-top: 35%;
    margin-bottom: 30px;
}
.SuccessScreenText{
    font-size: 22px;
    text-align: center;
    animation: 3s anim-lineUp ease-out
}
.SuccessScreenTextStrong{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-family: 'Raleway';
    color: #ff7110;
    animation: 1.5s anim-lineUp ease-out
}




.checkmark__circle {
    
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 10;
    stroke-miterlimit: 0;
    stroke: #ff7110;
    fill: none;
    animation: strokes 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    
    width: 156px;
    height: 156px;
    border-radius: 50%;
    display: block;
    stroke-width: 4;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    /* box-shadow: inset 0px 0px 0px #ff7110; */

    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 2s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;

    }
}

@keyframes strokes {
    20% {
        stroke-dashoffset: 0;

    }

    100% {
        stroke-dashoffset: 0;
        stroke-width: 50
    }
}

@keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
@keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
}

@keyframes fill {
    100% {
        /* box-shadow: inset 0px 0px 0px 30px #7ac142 */

        box-shadow: 1px 0px 20px 0px #ff7110;
        -webkit-box-shadow: 1px 0px 20px 0px #ff7110;
        -moz-box-shadow: 1px 0px 20px 0px #ff7110;
    }
}