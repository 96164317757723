.selectStyle {}

.selectStyle:focus {

    background-color: red;
    border-color: #ff7110 !important;

}

.inputStyle {
    outline: none;


}

.inputStyle:focus {
    box-shadow: none !important;
    border-color: #ff7110 !important;

}

.buttonCellPrimary {
    padding: 14px 5px;
    border-radius: 12px;
    background-color: #ff7110;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.2);
    /* border: 2px solid #ff7110; */
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.buttonCellPrimary:active {
    background-color: rgba(255, 113, 16, 0.9);
    
}
.buttonCellSecondary:active {
    background-color: rgba(255, 113, 16, 0.05);
    
}

.buttonCellSecondary {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    padding: 14px 5px;
    border-radius: 8px;
    border: 2px solid #ff7110;
    color: #ff7110;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
}

.buttonCellContainer {
    display: flex;
    flex-direction: column;
    margin-top:40px;
    /* gap: 30px !important; */
  
}